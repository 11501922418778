import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from "@mui/material/styles";
import React, {
    ReactElement,
    useState,
    useRef,
    useEffect,
    RefObject,
} from "react";
import isEqual from "react-fast-compare";
import { ThemeProvider } from "styled-components";

import Icon from "components/Icon";

import {
    Wrapper,
    Container,
    Language,
    Site,
    Sites,
    SiteName,
    Languages,
    LanguageSelected,
    OpenDialogButton,
    Check,
} from "./LanguagePicker.styled";
import LanguagePickerProps from "./LanguagePickerProps";

const LanguagePicker = ({
    sites,
    className,
    inHeader = false,
}: LanguagePickerProps): ReactElement => {
    const [open, setOpen] = useState<boolean>(false);
    const languagePickerRef = useRef(null);
    const buttonRef = useRef(null);

    const currentSite = sites?.find((site) => {
        return site.languages?.some((language) => language.isSelected);
    });

    const currentLanguage = currentSite?.languages?.find(
        (language) => language.isSelected,
    );

    const handleClick = (): void => {
        setOpen(!open);
    };

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (
                !open ||
                [languagePickerRef, buttonRef].some(
                    (r: RefObject<HTMLDivElement>) =>
                        r.current && r.current.contains(event.target as Node),
                )
            ) {
                return;
            }
            setOpen(false);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [open, languagePickerRef, buttonRef]);

    return (
        <MuiThemeProvider theme={createTheme()}>
            <ThemeProvider
                theme={{
                    inHeader: inHeader,
                }}
            >
                <Container className={className} id="languages">
                    <OpenDialogButton
                        onClick={handleClick}
                        ref={buttonRef}
                        title={currentSite?.name}
                    >
                        <Icon icon={open ? "close28" : "globe28"} />
                        {!inHeader && (
                            <span>
                                {currentSite?.name}
                                {currentLanguage &&
                                    ` (${currentLanguage?.name})`}
                            </span>
                        )}
                    </OpenDialogButton>
                    <Wrapper $isOpen={open} ref={languagePickerRef}>
                        <Sites>
                            {sites?.map((site, index) => (
                                <Site key={`site-${index}`}>
                                    <SiteName>{site.name}</SiteName>
                                    <Languages>
                                        {site.languages?.map(
                                            (language, languageIndex) => {
                                                return language.isSelected ? (
                                                    <LanguageSelected
                                                        key={`language-${languageIndex}`}
                                                    >
                                                        <Check icon="check28" />
                                                        {language.name}
                                                    </LanguageSelected>
                                                ) : (
                                                    <Language
                                                        url={language.url}
                                                        title={language.name}
                                                        key={`language-${languageIndex}`}
                                                        fullfetch={true}
                                                    >
                                                        {language.name}
                                                    </Language>
                                                );
                                            },
                                        )}
                                    </Languages>
                                </Site>
                            ))}
                        </Sites>
                    </Wrapper>
                </Container>
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default React.memo(LanguagePicker, isEqual);
