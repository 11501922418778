import { styled, css } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import Link from "components/Link";
import { styleBodyM, styleHeadingS } from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const backgroundColor = theme("inHeader", {
    true: "--background-default",
    false: "--block-blue-default",
});

const backgroundBorderColor = theme("inHeader", {
    true: "--line-on-neutral-default",
    false: "--line-on-blue-default",
});

const textColor = theme("inHeader", {
    true: "--text-on-neutral-primary",
    false: "--text-inverted",
});

const linkBorderColor = theme("inHeader", {
    true: "--text-on-neutral-link-alt-border",
    false: "--text-inverted-link-border",
});

export const LanguagePicker = styled.div`
    align-self: flex-end;
    grid-column: 1 / span 2;
    grid-row: 2;

    svg {
        margin-right: 0.4375rem;
        vertical-align: -0.5em;
    }
`;

export const Container = styled.div`
    align-items: center;
    display: flex;
`;

export const OpenDialogButton = styled.button`
    background: none;
    border: none;
    color: var(--text-inverted);
    cursor: pointer;
    transition:
        border-color 0.2s ease-in-out,
        color 0.2s ease-in-out;
    ${theme("inHeader", {
        true: css`
            color: inherit;
            transition: none;

            &:hover {
                color: var(--button-on-neutral-outline-hover);
            }
        `,
    })};

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }

    span {
        border-bottom: 1px solid var(--color-white-o00);
        margin-left: 0.875rem;
    }

    &:hover span {
        border-bottom: 1px solid var(--text-inverted);
    }
`;

export const Wrapper = styled.div.attrs<{
    $isOpen: boolean;
}>(() => ({}))`
    background-color: var(${backgroundColor});
    border-radius: 0.25rem;
    bottom: 4.375rem;
    box-shadow: 0 -1.75rem 1.75rem rgba(0 0 0 / 20%);
    color: var(${textColor});
    display: ${(props) => (props.$isOpen ? "block" : "none")};
    grid-column: 1 / span 4;
    left: 0;
    padding-bottom: 0.4375rem;
    position: absolute;
    width: 100%;
    ${theme("inHeader", {
        true: css`
            bottom: auto;
            box-shadow: 0 1.75rem 1.75rem rgba(0 0 0 / 20%);
            grid-column: 1 / span 4;
            top: 4.375rem;
            z-index: 1;
        `,
    })};

    ${MQ.FROM_M} {
        bottom: 3.75rem;
        grid-column: 4 / span 6;
        ${theme("inHeader", {
            true: css`
                bottom: auto;
                grid-column: 6 / span 6;
                top: 5.375rem;
            `,
        })};
    }

    ${MQ.FROM_L} {
        grid-column: 1 / span 4;
        ${theme("inHeader", {
            true: css`
                grid-column: 8 / span 4;
            `,
        })};
    }

    ${MQ.FROM_XL} {
        grid-column: 1 / span 3;
        ${theme("inHeader", {
            true: css`
                grid-column: 9 / span 3;
                top: 5.75rem;
            `,
        })};
    }
`;

export const Sites = styled.div`
    display: flex;
    flex-flow: column nowrap;
`;

export const Site = styled.div`
    border-bottom: 1px solid var(${backgroundBorderColor});
    padding: 1.3125rem;

    &:last-child {
        border-bottom: none;
    }
`;

export const SiteName = styled.div`
    ${styleHeadingS}
    color: var(${textColor});
    margin-bottom: 0.4375rem;
`;

export const Languages = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0.4375rem 1.3125rem;
`;

export const LanguageSelected = styled.span`
    ${styleBodyM};
    color: var(${textColor});
`;

export const Check = styled(Icon)`
    margin-right: 0.4375rem;
`;

export const Language = styled(Link)`
    ${styleBodyM};
    border-color: var(${linkBorderColor});
    color: var(${textColor});

    &:hover {
        border-color: var(${textColor});
    }
`;
